import React from 'react';
import { countriesISO3166Alpha2 } from '../util/countries';

const PaymentForm = ({ form, formChange, submitCcInfo, children }) => {
  const isEditing = true;
  return (
    <fieldset disabled={!isEditing}>
      <div className="form-row">
        <div className="form-group col-md-12">
          <input
            type="text"
            className="form-control"
            id="name"
            name="name"
            value={form.name}
            onChange={formChange}
            placeholder="Name"
          />
          <label htmlFor="name">Name</label>
        </div>
      </div>
      {children}

      {/* ie, is adding new card */}
      {(!children || !submitCcInfo) && (
        <div className="form-row">
          <div className="form-group col-md-6">
            <input
              type="text"
              className="form-control"
              id="exp_month"
              name="exp_month"
              value={form.exp_month}
              onChange={formChange}
              placeholder="Name"
            />
            <label htmlFor="exp_month">Exp. Month</label>
          </div>
          <div className="form-group col-md-6">
            <input
              type="text"
              className="form-control"
              id="exp_year"
              name="exp_year"
              value={form.exp_year}
              onChange={formChange}
              placeholder="Name"
            />
            <label htmlFor="exp_year">Exp. Year</label>
          </div>
        </div>
      )}

      <h5 className="mt-3">
        <strong>Billing Address</strong>
      </h5>
      <div className="form-group">
        <input
          type="text"
          className="form-control"
          id="address_line1"
          name="address_line1"
          value={form.address_line1}
          onChange={formChange}
          placeholder="Street Address"
        />
        <label htmlFor="address_line1">Street Address 1</label>
      </div>
      <div className="form-group">
        <input
          type="text"
          className="form-control"
          id="address_line2"
          name="address_line2"
          value={form.address_line2 !== null ? form.address_line2 : ''}
          onChange={formChange}
          placeholder="Street Address"
        />
        <label htmlFor="address_line2">Street Address 2</label>
      </div>
      <div className="form-row">
        <div className="form-group col-md-6">
          <input
            type="text"
            className="form-control"
            id="address_city"
            name="address_city"
            value={form.address_city}
            onChange={formChange}
            placeholder="City"
          />
          <label htmlFor="address_city">City</label>
        </div>
        <div className="form-group col-md-6">
          <input
            type="text"
            className="form-control"
            id="address_state"
            name="address_state"
            value={form.address_state}
            onChange={formChange}
            placeholder="State or Province"
          />
          <label htmlFor="address_state">State or Province</label>
        </div>
      </div>

      <div className="form-row">
        <div className="form-group col-md-6">
          <select
            id="address_country"
            name="address_country"
            value={form.address_country}
            onChange={formChange}
            className="form-control"
          >
            <option value=""> </option>
            {countriesISO3166Alpha2.map(x => (
              <option
                key={x.code}
                value={x.code}
                className={form.address_country === x.code ? 'active' : ''}
              >
                {x.name}
              </option>
            ))}
          </select>
          {form.address_country && form.address_country.length === 0 && (
            <label htmlFor="address_country">Country</label>
          )}
        </div>
        <div className="form-group col-md-6">
          <input
            type="text"
            className="form-control"
            id="address_zip"
            name="address_zip"
            value={form.address_zip}
            onChange={formChange}
            placeholder="Zip"
          />
          <label htmlFor="address_zip">Zip</label>
        </div>
      </div>
      {submitCcInfo && (
        <div className="form-row">
          <div className="form-group">
            <button type="button"
              className="btn btn-primary"
              onClick={submitCcInfo}>Add Card</button>
          </div>
        </div>)
      }
    </fieldset>
  );
};

export default PaymentForm;
