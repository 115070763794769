import React from 'react';
import propTypes from 'prop-types';
import SuccessIndicator from './SuccessIndicator';
import { countriesISO3166Alpha2 } from '../util/countries';

const ContactInfo = ({form, formChange, updateContactInfo}) => {
  return (
    <>
      <div className="row">
        <div className="col-md-8 col-lg-7 mx-auto contain-xl-content">
          <h1 className="title">Contact Info</h1>
          <p>
            Update your contact information and mailing address to make sure you
            receive your annual souvenir and regular newsletters.
          </p>
          <span className="divider-blue"></span>
        </div>
      </div>

      <div className="row">
        <div className="col-md-8 col-lg-7 mx-auto contain-xl-content tb-form">
          <form>
            <div className="form-row">
              <div className="form-group col-md-12">
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  value={form.name}
                  onChange={formChange}
                  placeholder="Name"
                />
                <label htmlFor="name">Name</label>
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-12">
                <input
                  type="text"
                  className="form-control"
                  id="phone"
                  name="phone"
                  value={form.phone}
                  onChange={formChange}
                  placeholder="Phone Number"
                />
                <label htmlFor="phone">Phone Number</label>
              </div>
            </div>

            {/* To Do: Migrate to fieldset heading */}
            <h5 className="mt-3">
              <strong>Mailing Address</strong>
            </h5>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                id="line1"
                name="line1"
                value={form.line1}
                onChange={formChange}
                placeholder="Street Address"
              />
              <label htmlFor="line1">Street Address 1</label>
            </div>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                id="line2"
                name="line2"
                value={form.line2 == null ? '' : form.line2}
                onChange={formChange}
                placeholder="Street Address"
              />
              <label htmlFor="line2">Street Address 2</label>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <input
                  type="text"
                  className="form-control"
                  id="city"
                  name="city"
                  value={form.city}
                  onChange={formChange}
                  placeholder="City"
                />
                <label htmlFor="city">City</label>
              </div>
              <div className="form-group col-md-6">
                <input
                  type="text"
                  className="form-control"
                  id="state"
                  name="state"
                  value={form.state}
                  onChange={formChange}
                  placeholder="State or Province"
                />
                <label htmlFor="state">State or Province</label>
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-6">
                <select
                  id="country"
                  name="country"
                  value={form.country}
                  onChange={formChange}
                  className="form-control">
                  <option value="">
                    {' '}
                  </option>
                  {countriesISO3166Alpha2.map(x => (
                    <option key={x.code}
                      value={x.code}
                      className={form.country === x.code ? 'active' : ''}>{x.name}</option>
                  ))}
                </select>
                {form.country && form.country.length === 0 &&
                  <label htmlFor="country">Country</label>}
              </div>
              <div className="form-group col-md-6">
                <input
                  type="text"
                  className="form-control"
                  id="postal_code"
                  name="postal_code"
                  value={form.postal_code}
                  onChange={formChange}
                  placeholder="Zip"
                />
                <label htmlFor="postal_code">Zip</label>
              </div>
            </div>

            <button
              type="button"
              className="btn btn-primary"
              onClick={updateContactInfo}>
              Update
            </button>
            {form.recentUpdateSuccess && <SuccessIndicator />}
          </form>
        </div>
      </div>
    </>
  );
};

ContactInfo.propTypes = {
  form: propTypes.shape({
    name: propTypes.string,
    phone: propTypes.string,
    line1: propTypes.string,
    line2: propTypes.string,
    city: propTypes.string,
    state: propTypes.string,
    country: propTypes.string,
    postal_code: propTypes.string,
  }),
  formChange: propTypes.func,
  updateContactInfo: propTypes.func,
}

export default ContactInfo;
