import React, { useState } from 'react';
import classnames from 'classnames';
import account from '../img/account.svg';
import trailblazer from '../img/trailblazer.svg';
import { stateCodes } from '../util/util';

const Nav = ({ userName, signOut, email, stateCode, changeState }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isNavDropdownOpen, setIsNavDropdownOpen] = useState(false);
  return (
    <nav className="navbar navbar-light fixed-top flex-md-nowrap p-0">
      <a className="navbar-brand col-md-3 col-lg-2 mr-0" href="/">
        <img
          className="trailblazer-logo"
          alt="trailblazer logo"
          src={trailblazer}
        />
        <span className="sr-only">Trailblazers</span>
      </a>
      <button className="navbar-toggler d-block d-sm-none"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
        onClick={() => setIsNavDropdownOpen(!isNavDropdownOpen)}>
        <span className="navbar-toggler-icon"></span>
      </button>
      <ul className="navbar-nav px-3">
        <li className="nav-item dropdown">
          <span className="icon-account"
            data-email={email}>
            <img alt="account icon" src={account} />
          </span>
          <span
            className="nav-link dropdown-toggle"
            onClick={() => {
              setIsDropdownOpen(!isDropdownOpen);
            }}
            id="dropdown04"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded={isDropdownOpen}
          >
            {userName ? userName : email}
          </span>
          <div
            className={`dropdown-menu${isDropdownOpen ? ' show' : ''}`}
            aria-labelledby="dropdown04"
          >
            <button className="dropdown-item" onClick={signOut}>
              Sign Out
            </button>
          </div>
        </li>
      </ul>
      <div
        className={classnames('navbar-collapse', { collapse: !isNavDropdownOpen })}
        id="navbarSupportedContent" >
        <ul className="navbar-nav mr-auto">
          <li className={classnames('nav-item', { active: stateCode === stateCodes.PAYMENT_DETAILS})}>
            <span className="nav-link" onClick={() => {
              changeState(stateCodes.PAYMENT_DETAILS);
              setIsNavDropdownOpen(!isNavDropdownOpen);
            }}>Payment Details</span>
          </li>
          <li className={classnames('nav-item', { active: stateCode === stateCodes.CONTACT_INFO})}>
            <span className="nav-link" onClick={() => {
              changeState(stateCodes.CONTACT_INFO);
              setIsNavDropdownOpen(!isNavDropdownOpen);
            }}>Contact Info</span>
          </li>
          <li className={classnames('nav-item', { active: stateCode === stateCodes.ACCOUNT})}>
            <span className="nav-link" onClick={() => {
              changeState(stateCodes.ACCOUNT);
              setIsNavDropdownOpen(!isNavDropdownOpen);
            }}>Account</span>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Nav;
