import React, { Component } from 'react'

import Amplify, { Hub } from 'aws-amplify';
import { Auth, I18n } from 'aws-amplify';
import { Authenticator, Loading } from 'aws-amplify-react';

import './App.scss';

import ContactInfo from './components/ContactInfo';
import Account from './components/Account';
import Nav from './components/Nav';
import Sidebar from './components/Sidebar';
import Body from './components/Body';
import PaymentDetails from './components/PaymentDetails';
import Login from './components/Login';
import ResetPassword from './components/ResetPassword';
import NewPasswordRequired from './components/NewPasswordRequired';

import awsCelebrateConfig from './aws-celebrate-config';
import { stateCodes,
  celebrateAmpTheme,
  bindTrailingArgs,
  contactInfoFormFromUserData,
  paymentDetailsFormFromUserData,
  paymentDetailsFormPartialFromPaymentCard,
  emptyContactInfoForm,
  emptyPaymentDetailsForm,
} from './util/util';
import {
  requestUserInfo,
  updateUserInfo,
  updatePaymentDetails,
  removePaymentSource,
  addPaymentSource,
  cancelSubscription,
  reEnableSubscription,
} from './util/trailblazerApi';

const authScreenLabels = {
  en: { 'Username': 'Email Address', 'Sign In': 'Log In', }
};
I18n.setLanguage('en');
I18n.putVocabularies(authScreenLabels);

Amplify.configure(awsCelebrateConfig);

export class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stateCode: stateCodes.PAYMENT_DETAILS,
      contactInfoForm: emptyContactInfoForm,
      paymentDetailsForm: emptyPaymentDetailsForm,
      accountForm: {},
      userData: {},
    };
    this._validAuthStates = ['signedIn'];
    this.signOut = this.signOut.bind(this);
    this.changeState = this.changeState.bind(this);
    this.getBodyElement = this.getBodyElement.bind(this);
    this.onFormChange = this.onFormChange.bind(this);
    this.updateContactInfo = this.updateContactInfo.bind(this);
    this.updateContactInfoSuccess = this.updateContactInfoSuccess.bind(this);
    this.updateContactInfoError = this.updateContactInfoError.bind(this);
    this.updatePaymentDetails = this.updatePaymentDetails.bind(this);
    this.updatePaymentDetailsSuccess = this.updatePaymentDetailsSuccess.bind(this);
    this.updatePaymentDetailsError = this.updatePaymentDetailsError.bind(this);
    this.removePaymentMethod = this.removePaymentMethod.bind(this);
    this.removePaymentMethodSuccess = this.removePaymentMethodSuccess.bind(this);
    this.removePaymentMethodError = this.removePaymentMethodError.bind(this);
    this.addPaymentMethod = this.addPaymentMethod.bind(this);
    this.addPaymentMethodSuccess = this.addPaymentMethodSuccess.bind(this);
    this.addPaymentMethodError = this.addPaymentMethodError.bind(this);
    this.cancelSubscription = this.cancelSubscription.bind(this);
    this.reEnableSubscription = this.reEnableSubscription.bind(this);
    this.handleAuthSuccess = this.handleAuthSuccess.bind(this);
    this.delayStateUpdate = this.delayStateUpdate.bind(this);
  }

  componentDidMount() {
    const errorMessage = 'Not signed in';
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
          Auth.currentAuthenticatedUser()
            .then(this.handleAuthSuccess)
            .catch(() => console.log(errorMessage));
          break;
        default:
          break;
      }
    });
    Auth.currentAuthenticatedUser()
      .then(this.handleAuthSuccess)
      .catch(() => console.log(errorMessage));
  }

  handleAuthSuccess(authData) {
    requestUserInfo(authData).then(response => {
      const userData = response.data;
      const contactInfoForm = contactInfoFormFromUserData(userData);
      const paymentDetailsForm = paymentDetailsFormFromUserData(userData);
      this.setState({ contactInfoForm, paymentDetailsForm, userData });
    }).catch(error => {
      console.log(error)
      this.props.onStateChange('signIn');
    });
  }

  onFormChange(e, formKey) {
    const value = e.currentTarget.type === 'checkbox' ? e.currentTarget.checked : e.currentTarget.value;
    const name = e.currentTarget.name;
    let form = this.state[formKey];
    form[name] = value.replace(/^\$/, '');// for donation quantity
    const newStateVals = {};
    newStateVals[formKey] = form;
    this.setState({ ...newStateVals });
  }

  delayStateUpdate(stateKey, fieldKey, value, wait=5000){
    setTimeout(() => {
      const stateProp = this.state[stateKey];
      stateProp[fieldKey] = value;
      this.setState({ [stateKey]: stateProp });
    }, wait);
  }

  cancelSubscription() {
    Auth.currentAuthenticatedUser().then((authData) => {
      cancelSubscription(authData)
        .then(this.updatePaymentDetailsSuccess)
        .catch(this.updatePaymentDetailsError);
    });
  }
  reEnableSubscription() {
    Auth.currentAuthenticatedUser().then((authData) => {
      const {paymentDetailsForm} = this.state;
      reEnableSubscription(authData, paymentDetailsForm)
        .then(this.updatePaymentDetailsSuccess)
        .catch(this.updatePaymentDetailsError);
    });
  }

  changeState(stateCode){
    if(!stateCodes[stateCode]){
      console.log(`invalid statecode ${stateCode}`);
      return;
    }
    this.setState({stateCode: stateCode})
  }

  signOut() {
    Auth.signOut().then(() => {
      this.props.onStateChange('signIn', {});
    }).catch(e => {
      console.log(e);
    });
  }

  updatePaymentDetails() {
    const { paymentDetailsForm } = this.state;
    console.log('updatePaymentDetails');
    Auth.currentAuthenticatedUser().then(authData => {
      updatePaymentDetails(authData, paymentDetailsForm)
        .then(this.updatePaymentDetailsSuccess)
        .catch(this.updatePaymentDetailsError);
    });
  }
  updatePaymentDetailsSuccess(response){
    const partialUserData = {...response.data};
    const paymentDetailsForm = paymentDetailsFormFromUserData(partialUserData);
    paymentDetailsForm.recentUpdateSuccess = true;
    this.setState({ paymentDetailsForm });
    this.delayStateUpdate('paymentDetailsForm', 'recentUpdateSuccess', false);
  }

  updatePaymentDetailsError(error){
    console.error(error);
    const { paymentDetailsForm } = this.state;
    paymentDetailsForm.recentUpdateFailure = true;
    this.setState({ paymentDetailsForm });
    this.delayStateUpdate('paymentDetailsForm', 'recentUpdateFailure', false);
  }

  removePaymentMethod() {
    Auth.currentAuthenticatedUser().then((authData) => {
      const { paymentDetailsForm } = this.state;
      removePaymentSource(authData, paymentDetailsForm.id)
        .then(this.removePaymentMethodSuccess)
        .catch(this.removePaymentMethodError);
    });
  }
  removePaymentMethodSuccess(response){
    let { paymentDetailsForm } = this.state;
    paymentDetailsForm = { ...paymentDetailsForm, ...emptyPaymentDetailsForm };
    this.setState({ recentUpdateSuccess: true, paymentDetailsForm });
    this.delayStateUpdate('paymentDetailsForm', 'recentUpdateSuccess', false);
  }
  removePaymentMethodError(response) {
    let { paymentDetailsForm } = this.state;
    paymentDetailsForm.recentUpdateFailure = true;
    this.setState({ paymentDetailsForm });
    this.delayStateUpdate('paymentDetailsForm', 'recentUpdateFailure', false);
  }

  addPaymentMethod(tokenId) {
    Auth.currentAuthenticatedUser().then((authData) => {
      addPaymentSource(authData, tokenId)
        .then(this.addPaymentMethodSuccess)
        .catch(this.addPaymentMethodError);
    });
  }
  addPaymentMethodSuccess(response){
    let { paymentDetailsForm } = this.state;
    paymentDetailsForm = { ...paymentDetailsForm, ...emptyPaymentDetailsForm };
    const paymentDetailsFormPatch = paymentDetailsFormPartialFromPaymentCard(response.data);
    const updatedPaymentDetailsForm = {...paymentDetailsForm, ...paymentDetailsFormPatch};
    this.setState({
      paymentDetailsForm: updatedPaymentDetailsForm,
      recentUpdateSuccess: true
    });
    this.delayStateUpdate('paymentDetailsForm', 'recentUpdateSuccess', false);
  }
  addPaymentMethodError(){
    this.delayStateUpdate('paymentDetailsForm', 'recentUpdateFailure', false);
  }

  updateContactInfo() {
    Auth.currentAuthenticatedUser().then((authData) => {
      const { contactInfoForm } = this.state;
      updateUserInfo(authData, contactInfoForm).then(this.updateContactInfoSuccess);
    });
  }

  updateContactInfoSuccess(response) {
    const userData = response.data;
    const contactInfoForm = contactInfoFormFromUserData(userData);
    contactInfoForm.recentUpdateSuccess = true;
    this.setState({ contactInfoForm, userData });
    this.delayStateUpdate('contactInfoForm', 'recentUpdateSuccess', false);
  }

  updateContactInfoError(error){
    console.error(error);
    const { contactInfoForm } = this.state;
    contactInfoForm.recentUpdateFailure = true;
    this.setState({ contactInfoForm });
    this.delayStateUpdate('contactInfoForm', 'recentUpdateFailure', false);
  }

  getBodyElement() {
    const { state, props } = this;

    switch (state.stateCode) {
      case stateCodes.CONTACT_INFO:
        const contactFormKey = 'contactInfoForm';
        return (
          <ContactInfo
            form={state.contactInfoForm}
            formChange={bindTrailingArgs(this.onFormChange, contactFormKey)}
            updateContactInfo={this.updateContactInfo} />
          );
      case stateCodes.PAYMENT_DETAILS:
        const paymentDetailsFormKey = 'paymentDetailsForm';
        return (
          <PaymentDetails
            userData={state.userData}
            form={state.paymentDetailsForm}
            formChange={bindTrailingArgs(this.onFormChange, paymentDetailsFormKey)}
            updatePaymentDetails={this.updatePaymentDetails}
            removePaymentMethod={this.removePaymentMethod}
            addPaymentMethod={this.addPaymentMethod}
            cancelSubscription={this.cancelSubscription}
            reEnableSubscription={this.reEnableSubscription}
          />
          );
        //   return <Donation />
      case stateCodes.ACCOUNT:
        return <Account {...props} />;
      default:
        break;
    }
  }

  render() {
    const { authState, authData } = this.props;
    const { userData, stateCode } = this.state;
    if (authState !== 'signedIn') { return null; }
    // console.log({ authData, authState, userData });
    const userName = userData ? userData.name : authData.username;
    const email = authData.username;
    return (
      <>
        <Nav
          userName={userName}
          signOut={this.signOut}
          email={email}
          stateCode={stateCode}
          changeState={this.changeState}
        />
        <div className="container-fluid">
          <div className="row">
            <Sidebar stateCode={stateCode} changeState={this.changeState} />
            <Body>
              {this.getBodyElement()}
            </Body>
          </div>
        </div>
      </>
    )
  }
}

const AuthWrappedApp = () => {
  return (
    <Authenticator
      theme={celebrateAmpTheme}
      hideDefault={true} >
      <App />
      <Loading />
      <Login />
      <ResetPassword />
      <NewPasswordRequired />
    </Authenticator>
  );
};
export default AuthWrappedApp;
