import React from 'react';

const Account = () => {
  return (
    <React.Fragment>
      <div className="row">
        <div className="col-md-7 mx-auto">
          <h1 className="title">Account</h1>
          <p>If you have questions about your Trailblazer gift or need to update information that you don’t see here. Call 720-361-8409 or email jenny@celebrateEDU.org.</p>
          <p>If you need to reset your password, please log out and use the Reset Password link on the sign-in form</p>
          <span className="divider-blue"></span>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Account;
