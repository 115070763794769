import React, { useState, useRef } from 'react';
import dayjs from 'dayjs';
import classnames from 'classnames';

import SuccessIndicator from './SuccessIndicator';
import { padNumber } from '../util/util';
import stripeSvg from '../img/stripe.svg';
import PaymentForm from './PaymentForm';
import AddPayment from './AddPayment';

const getSubscriptionText = form => {
  const { nextCycleStartDate, persistedQuantity, id, expiring } = form;
  const currentDonationStatus = `Your current donation is $${persistedQuantity}/month`;
  if (expiring) {
    return `You have suspended your current donation of $${persistedQuantity}/month. 💔`
  }
  if (!id) {
    return `${currentDonationStatus}. You do not have an active card though! 💔`
  }
  const nextDonationString = dayjs
    .unix(nextCycleStartDate)
    .format('MMMM D, YYYY');
  const billingInfo = `You are donating with ${form.brand} ending in ${form.last4}. Expires ${padNumber(form.exp_month, 2, 0)}/${form.exp_year}.`;

  return `${currentDonationStatus} and your next contribution date is ${nextDonationString}. ❤️ \n${billingInfo}`;
};

const PaymentDetails = ({
  form,
  formChange,
  updatePaymentDetails,
  removePaymentMethod,
  addPaymentMethod,
  cancelSubscription,
  reEnableSubscription,
}) => {
  const [isEditingOtherAmount, setIsEditingOtherAmount] = useState(false);
  const [isCancelPrimed, setIsCancelPrimed] = useState(false);
  const otherAmountInput = useRef(null);
  const isSubscriptionActive = form.status === 'active' && !form.expiring;
  const predefinedAmounts = ['10','30','50'];
  const usingPredefinedAmount = predefinedAmounts.includes(form.quantity);
  const isAddingNewPayment = form.id ? false : true;
  const hideValueForOtherDonationAmount =
    usingPredefinedAmount ||
    form.quantity === '' ||
    form.quantity === form.persistedQuantity ||
    isNaN(form.quantity);
  return (
    <>
      <div className="row">
        <div className="col-md-8 col-lg-7 mx-auto contain-xl-content">
          <h1 className="title">Trailblazer Status</h1>
          <p>{getSubscriptionText(form)}</p>
          <span className="divider-blue"></span>
        </div>
      </div>

      {form && (
        <div className="row">
          <div className="col-md-8 col-lg-7 mx-auto contain-xl-content tb-form">
            <form>
              <h5 className="mt-3">
                <strong>Monthly Donation</strong>
              </h5>
              {/* <DonationAmounts onChange={formChange}/> */}
              <div className="form-group fieldset row donation-amounts">
                {predefinedAmounts.map(amount => {
                  const active = amount === form.quantity;
                  const className = classnames('btn btn-outline-warning', { active });
                  const eventData = {currentTarget: { name: 'quantity', value: amount, type: 'text' }};
                  return (
                    <div key={`amount-${amount}`} className="col-6 col-sm-3">
                      <button
                        type="button"
                        className={className}
                        onClick={() => formChange(eventData)}
                      >
                        ${amount}
                      </button>
                    </div>
                  );
                })}
                <div className="col-6 col-sm-3">
                  {!isEditingOtherAmount && (
                    <button
                      type="button"
                      className={classnames('btn btn-outline-warning', { active: !usingPredefinedAmount })}
                      style={{ padding: '10px 2.5vw' }}
                      onClick={() => {
                        setIsEditingOtherAmount(!isEditingOtherAmount);
                        setTimeout(() => (
                          otherAmountInput.current.focus()
                        ), 200)
                      }}
                    >
                      Other Amt
                    </button>
                  )}
                  <input
                    ref={otherAmountInput}
                    style={{ display: isEditingOtherAmount ? 'initial': 'none' }}
                    className={classnames('tb-other-amt', 'form-control', { active: !usingPredefinedAmount })}
                    onChange={formChange}
                    type="text"
                    name="quantity"
                    placeholder="Other Amt"
                    value={hideValueForOtherDonationAmount ? '' : `$${form.quantity}`}
                  />
                </div>
              </div>

              {isAddingNewPayment && (
                <AddPayment
                  form={form}
                  formChange={formChange}
                  addPaymentMethod={addPaymentMethod} />
              )}
              {!isAddingNewPayment && (
                <PaymentForm form={form} formChange={formChange} />
              )}
            </form>
            <div style={{ textAlign: 'right'}}>
              <p style={{ display: 'inline-block', margin: 0 }}>
                <small>
                  We process payments securely using{' '}
                  <img src={stripeSvg} alt="Stripe" />
                </small>
              </p>
            </div>

            {!isAddingNewPayment && (
              <>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    setIsEditingOtherAmount(false);
                    updatePaymentDetails();
                  }}
                >
                  Save
                </button>
                <button
                  type="button"
                  className="btn btn-secondary-outline"
                  onClick={(event) => {
                    setIsEditingOtherAmount(false);
                    removePaymentMethod(event);
                  }}
                >
                  Change Card
                </button>
                {isSubscriptionActive ? (
                  <button
                    type="button"
                    className={classnames('btn', isCancelPrimed ? 'btn-outline-danger' : 'btn-outline-warning')}
                    onClick={(event) => {
                      setIsEditingOtherAmount(false);
                      if (isCancelPrimed) {
                        setIsCancelPrimed(false);
                        cancelSubscription();
                      }
                      setIsCancelPrimed(true);
                    }}
                  >
                  Cancel Donation
                </button>
                ) : (
                  <button
                    type="button"
                    className={classnames('btn', 'btn-primary')}
                    onClick={(event) => {
                      setIsEditingOtherAmount(false);
                      reEnableSubscription();
                    }}
                  >
                    Re-Enable Donation
                  </button>
                )}
              </>
            )}
            {form.recentUpdateSuccess && <SuccessIndicator />}
            {form.recentUpdateFailure && <span style={{ color: '#FF6A39'}}>Error!</span>}
          </div>
        </div>
      )}
    </>
  );
};

export default PaymentDetails;
