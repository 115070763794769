import React from 'react';

const Body = ({children}) => {
  return (
    <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-4">
      {children}

      <footer className="mt-auto">
        <div className="row">
          <div className="col-md-7 mx-auto mt-5">
            <p>
              <a href="https://celebrateedu.org" target="_blank" rel="noopener noreferrer">
                Celebrate EDU
              </a>{' '}
              is a registered 501(c)3 nonprofit corporation
            </p>
          </div>
        </div>
      </footer>
    </main>
  );
};

export default Body;
