import React from 'react';
import { ForgotPassword } from 'aws-amplify-react';
import trailblazer from '../img/trailblazer-logo-dark.svg';

// https://github.com/aws-amplify/amplify-js/blob/master/packages/aws-amplify-react/src/Auth/ForgotPassword.tsx
export class ResetPassword extends ForgotPassword {
  constructor(props) {
    super(props);
    this._validAuthStates = ['forgotPassword', 'verifyContact' ];
  }

  sendView() {
    return (
      <form>
        <div className="form-row">
          <p>We will send you a verification code so you can set your password</p>
          <div className="form-group col-md-7">
            <input
              type="text"
              className="form-control"
              id="username"
              key="username"
              name="username"
              onChange={this.handleInputChange}
              placeholder="Email"
            />
            <label htmlFor="username">Email</label>
          </div>
        </div>
      </form>
    );
  }

  submitView() {
    return (
      <form>
        <div className="form-row">
          <p>Check you email for the verification code we just sent and enter it below.<br />Password should be at least 8 characters. <br/>It must include at least one uppercase, lowercase and numeric character.</p>
          <div className="form-group col-md-7">
            <input
              type="text"
              className="form-control"
              id="code"
              key="code"
              name="code"
              autoComplete="off"
              onChange={this.handleInputChange}
              placeholder="Code"
            />
            <label htmlFor="code">Code</label>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-7">
            <input
              type="password"
              className="form-control"
              id="password"
              key="password"
              name="password"
              autoComplete="off"
              placeholder="New Password"
              onChange={this.handleInputChange}
            />
            <label htmlFor="email">New Password</label>
          </div>
        </div>
      </form>
    );
  }

  showComponent(theme) {
    const {authData = {} } = this.props;
    // const { authState, hide, authData = {} } = this.props;
    // if (hide && hide.includes(ForgotPassword)) {
    //   return null;
    // }

    return (
      <div className="container-fluid">
        <div className="row">
          <div role="main" className="col-sm-5 offset-sm-1 login">
            <img
              src={trailblazer}
              className="login-logo"
              alt="trailblazer logo"
            />
            <h1 className="title sr-only">Log In</h1>
            <span className="divider-blue"></span>
            {this.state.delivery || authData.username
              ? this.submitView()
              : this.sendView()}

            {/* SectionFooterPrimaryContent */}
            {this.state.delivery || authData.username ? (
              <button
                type="button"
                className="btn btn-primary"
                onClick={this.submit}
              >
                Submit
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-primary"
                onClick={this.send}
              >
                Send Code
              </button>
            )}

            {/* SectionFooterSecondaryContent */}
            <div className="form-row">
              <div className="form-group col-md-7">
                {this.state.delivery || authData.username ? (
                  <span className="signin-altoption-text" onClick={this.send}>Resend Code</span>
                ) : (
                  <span className="signin-altoption-text" onClick={() => this.changeState('signIn')}>
                    Back to Sign In
                  </span>
                )}
              </div>
            </div>

            <a
              href="https://celebrateedu.org/donate/trailblazer/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Sign up to be a Trailblazer
            </a>

            <p style={{ marginTop: '17vw' }}>
              <a
                href="https://celebrateedu.org"
                target="_blank"
                rel="noopener noreferrer"
              >
                Celebrate EDU
              </a>{' '}
              is a registered 501(c)3 nonprofit corporation
            </p>
          </div>
          <div className="col-sm-6 login-image"></div>
        </div>
      </div>
    );
  }
}

export default ResetPassword;
