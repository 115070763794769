import axios from 'axios';

const trailblazerHost = process.env.REACT_APP_API_URL; // === 'production' ? 'https://app.celebrateedu.org/api/v1/trailblazer' : 'https://dev.app.celebrateedu.org/api/v1/trailblazer';
const getAuthHeader = (authData) => {
  const authToken = authData.signInUserSession.idToken.jwtToken;
  return { 'Authorization': 'Bearer ' + authToken };
}
export const requestUserInfo = (authData) => {
  // https://itnext.io/aws-cognito-example-using-react-ui-and-node-js-rest-apis-part-3-jwt-secured-rest-apis-e56d336ce306
  return axios.get(
    `${trailblazerHost}/account`,
    { headers: { ...getAuthHeader(authData) } },
  );
};

export const updateUserInfo = (authData, contactInfo) => {
  return axios.post(
    `${trailblazerHost}/account`,
    contactInfo,
    { headers: { ...getAuthHeader(authData) } },
  );
};

export const updatePaymentDetails = (authData, paymentInfo) => {
  return axios.put(
    `${trailblazerHost}/account/payment`,
    paymentInfo,
    { headers: { ...getAuthHeader(authData) } },
  );
};

export const removePaymentSource = (authData, cardId) => {
  return axios.delete(
    `${trailblazerHost}/account/payment/${cardId}`,
    { headers: { ...getAuthHeader(authData) } },
  );
};

export const addPaymentSource = (authData, tokenId) => {
  return axios.post(
    `${trailblazerHost}/account/payment`,
    {sourceToken: tokenId },
    { headers: { ...getAuthHeader(authData) } },
  );
};

export const cancelSubscription = (authData) => {
  return axios.delete(
    `${trailblazerHost}/account/subscription`,
    { headers: { ...getAuthHeader(authData) } },
  );
}

export const reEnableSubscription = (authData, paymentInfo) => {
  return axios.put(
    `${trailblazerHost}/account/subscription`,
    paymentInfo,
    { headers: { ...getAuthHeader(authData) } },
  );
}
