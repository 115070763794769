export const stateCodes = {
  SIGN_IN_REQUIRED: 'SIGN_IN_REQUIRED',
  // NEW_PASSWORD_REQUIRED: 'NEW_PASSWORD_REQUIRED',
  // MFA_REQUIRED: 'MFA_REQUIRED',
  // SIGNED_IN: 'SIGNED_IN',
  CONTACT_INFO: 'CONTACT_INFO',
  PAYMENT_DETAILS: 'PAYMENT_DETAILS',
  ACCOUNT: 'ACCOUNT',
};

export const celebrateAmpTheme = {
  sectionHeader: { 'display': 'none' },
  formSection: { 'boxShadow': 'none', 'width': '100%' },
  // formContainer: { 'border': 'none' },
  sectionBody: { 'border': 'none' },
  // formField: { 'backgroundColor': 'red', 'borderColor': 'red' },
  input: { 'borderRadius': '1.5rem',},
  label: { 'display': 'none' },
  inputLabel: { 'display': 'none' },
  button: {
    'padding': '10px 80px',
    'backgroundColor': '#00C7B1',
    'border': '0',
    'borderRadius': '30px',
    'fontSize': '1.1rem',
  },
}

const emptyContactInfoAddress =  {
  phone: '',
  line1: '',
  line2: '',
  city: '',
  state: '',
  country: '',
  postal_code: '',
  // recentUpdateSuccess
  // recentUpdateFailure
};

export const emptyContactInfoForm = {
  name: '',
  ...emptyContactInfoAddress,
};

export const emptyPaymentDetailsForm = {
  id: '', // not for editing
  name: '',
  exp_month: '',
  exp_year: '',
  address_line1: '',
  address_line2: '',
  address_city: '',
  address_state: '',
  address_country: '',
  address_zip: '',
  // Non form editable fields
  // subId: subscriptionInfo.subId,
  // subItemId: subscriptionInfo.subItemId,
  // planId: subscriptionInfo.planId,
  // quantity: subscriptionInfo.quantity,
  // recentUpdateSuccess: false,
  // recentUpdateFailure: false,
};

export const padNumber = (n, width = 3, z = 0)=> (
  (String(z).repeat(width) + String(n)).slice(String(n).length)
);

export const bindTrailingArgs = (fn, ...bound_args) => { // Some Stackoverflow answer..
  return (...args) => {
    return fn(...args, ...bound_args);
  };
};

export const contactInfoFormFromUserData = (userData) => {
  if (userData.shipping == null) { return emptyContactInfoForm; }
  const { address } = userData.shipping;
  const shippingAddress = address ? address : emptyContactInfoAddress;
  return {
    name: userData.shipping.name,
    phone: userData.shipping.phone,
    ...shippingAddress
  };
};

export const paymentDetailsFormPartialFromPaymentCard = (card) => {
  return {
    id:  card.id,
    name:  card.name,
    brand: card.brand,
    last4: card.last4,
    exp_month: card.exp_month,
    exp_year: card.exp_year,
    address_city: card.address_city,
    address_line1: card.address_line1,
    address_line2: card.address_line2,
    address_state: card.address_state,
    address_country: card.address_country,
    address_zip: card.address_zip,
  };
};

export const paymentDetailsFormFromUserData = (userData) => {
  const card = userData.paymentCard ? paymentDetailsFormPartialFromPaymentCard(userData.paymentCard) : undefined;
  const subscriptionInfo = userData.subscriptionInfo;
  let donationData;
  if (subscriptionInfo) {
    donationData = {
      subId: subscriptionInfo.subId,
      subItemId: subscriptionInfo.subItemId,
      planId: subscriptionInfo.planId,
      persistedQuantity: subscriptionInfo.quantity,
      status: subscriptionInfo.status,
      expiring: subscriptionInfo.expiring,
      quantity: subscriptionInfo.quantity,
      nextCycleStartDate: subscriptionInfo.nextCycleStartDate,
    };
  }
  return { ...card, ...donationData };
};

