import React from 'react';
import { stateCodes } from '../util/util';

const Sidebar = ({stateCode, changeState}) => {
  return (
    <nav className="col-md-3 col-lg-2 d-none d-md-block sidebar px-0">
      <div className="sidebar-sticky">
        <ul className="nav flex-column">
          <li className="nav-item">
            <span
              className={`nav-link${stateCode === stateCodes.PAYMENT_DETAILS ? ' active' : ''}`}
              onClick={() => changeState(stateCodes.PAYMENT_DETAILS)}>
              Payment Details
            </span>
          </li>
          <li className="nav-item">
            {/* <a className="nav-link" href="login.html">
              Login
            </a> */}
            <span
              className={`nav-link${stateCode === stateCodes.CONTACT_INFO ? ' active' : ''}`}
              onClick={() => changeState(stateCodes.CONTACT_INFO)}>
              Contact Info <span className="sr-only">(current)</span>
            </span>
          </li>
          <li className="nav-item">
            <span
              className={`nav-link${stateCode === stateCodes.ACCOUNT ? ' active' : ''}`}
              onClick={() => changeState(stateCodes.ACCOUNT)}>
                Account
            </span>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Sidebar;
