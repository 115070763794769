import React from 'react';
import { RequireNewPassword } from 'aws-amplify-react';
import trailblazer from '../img/trailblazer-logo-dark.svg';

export class NewPasswordRequired extends RequireNewPassword {
  constructor(props) {
    super(props);
    this._validAuthStates = ['requireNewPassword'];
  }

  showComponent(theme) {
    return (
      <div className="container-fluid">
        <div className="row">
          <div role="main" className="col-sm-5 offset-sm-1 login">
            <img
              src={trailblazer}
              className="login-logo"
              alt="trailblazer logo"
            />
            <h1 className="title sr-only">Change Password</h1>
            <span className="divider-blue"></span>

            <form>
              <div className="form-row">
              <p>Enter your new password<br />Password should be at least 8 characters. <br />It must include at least one uppercase, lowercase and numeric character.</p>
                <div className="form-group col-md-7">
                  <input
                    autoFocus
                    placeholder="New Password"
                    theme={theme}
                    autoComplete="off"
                    key="password"
                    name="password"
                    type="password"
                    onChange={this.handleInputChange}
                  />
                  <label htmlFor="password">New Password</label>
                </div>
              </div>
              <button
                type="button"
                className="btn btn-primary"
                onClick={(evt) => super.change(evt)}
              >
                Update Password
              </button>
            </form>

            <a
              href="https://celebrateedu.org/donate/trailblazer/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Sign up to be a Trailblazer
            </a>

            <p style={{ marginTop: '17vw' }}>
              <a
                href="https://celebrateedu.org"
                target="_blank"
                rel="noopener noreferrer"
              >
                Celebrate EDU
              </a>{' '}
              is a registered 501(c)3 nonprofit corporation
            </p>
          </div>
          <div className="col-sm-6 login-image"></div>
        </div>
      </div>
    );
  }
}

export default NewPasswordRequired;
