import React from 'react';
import { SignIn } from 'aws-amplify-react';
import trailblazer from '../img/trailblazer-logo-dark.svg';

export class Login extends SignIn {
  constructor(props) {
    super(props);
    this._validAuthStates = ['signIn', 'signedOut', 'signedUp'];
  }

  showComponent(theme) {
    const { error } = this.props;
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-6 d-md-none d-lg-none d-xl-none login-image"></div>
          <div role="main" className="col-md-6 col-lg-5 offset-lg-1 login">
            <img
              src={trailblazer}
              className="login-logo"
              alt="trailblazer logo"
            />
            <h1 className="title sr-only">Log In</h1>
            <span className="divider-blue"></span>

            <form>
              <div className="form-row">
                <div className="form-group col-md-9 col-lg-7">
                  <input
                    type="text"
                    className="form-control"
                    id="username"
                    key="username"
                    name="username"
                    autoComplete="off"
                    onChange={this.handleInputChange}
                    placeholder="Email"
                  />
                  <label htmlFor="username">Email</label>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-9 col-lg-7">
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    key="password"
                    name="password"
                    autoComplete="off"
                    placeholder="Password"
                    onChange={this.handleInputChange}
                  />
                  <label htmlFor="email">Password</label>
                  {/* <small className="form-text text-muted form-help-text">Must be at least 10 characters.</small> --> */}
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-9 col-lg-7">
                  <span className="signin-altoption-text" onClick={() => super.changeState('forgotPassword')}>
                    Reset Password
                  </span>
                </div>
              </div>
              <button
                type="button"
                className="btn btn-primary"
                onClick={(evt) => super.signIn(evt)}
              >
                Log In
              </button>
            </form>

            <a
              href="https://celebrateedu.org/donate/trailblazer/"
              target="_blank"
              rel="noopener noreferrer"
              className="login-signup"
            >
              Sign up to be a Trailblazer
            </a>

            <p style={{ marginTop: '17vw' }} className="footer-cred">
              <a
                href="https://celebrateedu.org"
                target="_blank"
                rel="noopener noreferrer"
              >
                Celebrate EDU
              </a>{' '}
              is a registered 501(c)3 nonprofit corporation
            </p>
          </div>
          <div className="col-sm-6 d-none d-md-block login-image"></div>
        </div>
      </div>
    );
  }
}

export default Login;
