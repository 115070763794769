import React from 'react';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements
} from '@stripe/react-stripe-js';
import PaymentForm from './PaymentForm';
const elementsStyles = {
  base: {
    fontFamily: 'Raleway',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '1.2em',
    color: '#59595b',
  }
};

const AddPaymentForm = ({ form, formChange, addPaymentMethod }) => {
  const stripe = useStripe();
  const elements = useElements();
  if (!elements || !stripe) {
    return null;
  }

  const submitCcInfo = async event => {
    event.preventDefault();
    const cardNumberElement = elements.getElement(CardNumberElement);

    const data = {
      name: form.name,
      phone: form.phone,
      email: form.email,
      address_line1: form.address_line1,
      address_city: form.address_city,
      address_state: form.address_state,
      address_zip: form.address_zip,
      address_country: form.address_country,
      currency: 'usd'
    };

    const { token, error } = await stripe.createToken(cardNumberElement, data);
    if (!error) {
      addPaymentMethod(token.id);
    } else {
      console.log(error);
    }
  };
  return (
    <>
      <PaymentForm
        form={form}
        formChange={formChange}
        submitCcInfo={submitCcInfo}
      >
        <CardNumberElement options={{style: elementsStyles }} />
        <CardExpiryElement options={{style: elementsStyles }} />
        <CardCvcElement options={{style: elementsStyles }} />
      </PaymentForm>
    </>
  );
};

export default AddPaymentForm;
