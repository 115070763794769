import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import AddPaymentForm from './AddPaymentForm';

const stripePromise = loadStripe(process.env.REACT_APP_CEL_STRIPE_PUBLIC_KEY);
const elementsOptions = {
  fonts: [
    { cssSrc: 'https://fonts.googleapis.com/css?family=Raleway&display=swap' }
  ]
};

const AddPayment = props => {
  return (
    <Elements stripe={stripePromise} options={elementsOptions}>
      <AddPaymentForm {...props} />
    </Elements>
  );
};

export default AddPayment;
